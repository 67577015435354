export const FORM_TYPES = {
  registration: 'REGISTRATION',
  login: 'LOGIN',
  forgotPassword: 'FORGOT_PASSWORD',
  otpVerification: 'OTP_VERIFICATION',
  resetPassword: 'RESET_PASSWORD',
  dimensions: 'DIMENSIONS',
  billingAddress: 'BILLING_ADDRESS',
  shippingAddress: 'SHIPPING_ADDRESS',
};
export const APP_FORMS = {
  registration: {
    formName: "Let's get an Account",
    ctaName: 'Register',
    actionText: 'HAVE AN ACCOUNT?',
    seo: { metaTitle: 'Register' },
    action: {
      alreadyRegistered: {
        markupText: 'Have an account? [Login](/auth/login)',
      },
    },
    socialIcons: [
      {
        icon: 'facebook',
        label: 'Facebook',
        actionLink: '',
        backgroundColor: '#395185',
        authConnect: '/connect/facebook',
      },
      {
        icon: 'google',
        label: 'Google',
        actionLink: '',
        backgroundColor: '#AD0000',
        authConnect: '/connect/google',
      },
    ],
    fields: [
      {
        attribute: 'name',
        dropdown: false,
        fieldType: {
          code: 'TEXT',
          name: 'TEXT',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 100,
          pattern: '.*',
          jsPattern:
            '^(?:\\s*)[^:“”\\u0022;!#@$%^&*()_+={}\\[<>,.?`~|\\]\\\\\\/]+(\\s+[^\\s\\d:“”\\u0022;!#@$%^&*()_+={}\\[<>,.?`~|\\]\\\\\\/]+)*(?:\\s*)$',
          replacePattern: '$1',
        },
        label: 'Your Name',
        placeholder: 'Ravindra Srinivasa',
        errorMessages: {
          pattern: 'Not matching regex',
          required: 'Enter your full name',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
      {
        attribute: 'email',
        dropdown: false,
        fieldType: {
          code: 'TEXT',
          name: 'TEXT',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          jsPattern:
            "^(?:\\s*)[_A-Za-z0-9#~!\\$&'\\(\\)\\*\\+,;=:%\\/\\?\\^`\\{\\|\\}-]+(\\.[_A-Za-z0-9#~!\\$&'\\(\\)\\*\\+,;=:%\\/\\?\\^`\\{\\|\\}-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})(?:\\s*)$",
          replacePattern: '$1',
        },
        label: 'Email',
        placeholder: 'ravindras@example.com',
        errorMessages: {
          pattern: 'Please enter a valid email address',
          required: 'Enter your email',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
      {
        attribute: 'phone',
        dropdown: false,
        fieldType: {
          code: 'TEXT',
          name: 'TEXT',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 15,
          minLength: 12,
          pattern: '.*',
          jsPattern: '^(\\[\\d]{1,5}|0)?[7-9]\\d{9}$',
          replacePattern: '$1',
        },
        label: 'Phone Number',
        placeholder: 'Phone Number',
        errorMessages: {
          pattern: 'Please enter valid phone number',
          required: 'Please enter phone number',
          minLength: 'Please enter valid phone number',
          maxLength: 'Please enter valid phone number',
        },
        disabled: false,
      },
      {
        attribute: 'password',
        dropdown: false,
        fieldType: {
          code: 'PASSWORD',
          name: 'PASSWORD',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          replacePattern: '$1',
        },
        label: 'Password',
        placeholder: 'secure password',
        errorMessages: {
          pattern: 'Not matching regex',
          required: 'Password required',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
      {
        attribute: 'cPassword',
        dropdown: false,
        fieldType: {
          code: 'PASSWORD',
          name: 'PASSWORD',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          replacePattern: '$1',
        },
        label: 'Confirm Password',
        placeholder: 'confirm secure password',
        errorMessages: {
          pattern: 'Not matching regex',
          required: 'Enter your confirm password',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
      {
        attribute: 'acceptTerms',
        dropdown: false,
        fieldType: {
          code: 'CHECKBOX',
          name: 'CHECKBOX',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {},
        helpText:
          'I agree to the [Terms of Service](https://digilinklabs.com/terms-of-service) and [Privacy Policy](https://digilinklabs.com/privacy-policy)',
        errorMessages: {
          required: 'Accept `Terms of Service` and `Privacy Policy`',
        },
        disabled: false,
      },
    ],
  },
  login: {
    formName: 'Login to DigiLinkLabs',
    ctaName: 'Login',
    seo: { metaTitle: 'Login' },
    action: {
      forgotPassword: {
        markupText: '[Forgot your password?](/auth/forgot-password)',
      },
      register: {
        markupText: "Don't have an account? [Register](/auth/register)",
      },
    },
    fields: [
      {
        attribute: 'email',
        dropdown: false,
        fieldType: {
          code: 'TEXT',
          name: 'TEXT',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          jsPattern:
            "^(?:\\s*)[_A-Za-z0-9#~!\\$&'\\(\\)\\*\\+,;=:%\\/\\?\\^`\\{\\|\\}-]+(\\.[_A-Za-z0-9#~!\\$&'\\(\\)\\*\\+,;=:%\\/\\?\\^`\\{\\|\\}-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})(?:\\s*)$",
          replacePattern: '$1',
        },
        label: 'Email ID',
        placeholder: 'youremail@example.com',
        errorMessages: {
          pattern: 'Please enter a valid email id',
          required: 'Enter your email id',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
      {
        attribute: 'password',
        dropdown: false,
        fieldType: {
          code: 'PASSWORD',
          name: 'PASSWORD',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          replacePattern: '$1',
        },
        label: 'Password',
        placeholder: 'password here...',
        errorMessages: {
          pattern: 'Not matching regex',
          required: 'Password required',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
    ],
  },
  forgotPassword: {
    formName: 'Forgot password?',
    seo: { metaTitle: 'Forgot password' },
    navigateTo: '/auth/login',
    headline: 'Forgot password?',
    introText: 'You can reset your password here.',
    ctaName: 'Send My Password',
    fields: [
      {
        attribute: 'email',
        dropdown: false,
        fieldType: {
          code: 'TEXT',
          name: 'TEXT',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          jsPattern:
            "^(?:\\s*)[_A-Za-z0-9#~!\\$&'\\(\\)\\*\\+,;=:%\\/\\?\\^`\\{\\|\\}-]+(\\.[_A-Za-z0-9#~!\\$&'\\(\\)\\*\\+,;=:%\\/\\?\\^`\\{\\|\\}-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})(?:\\s*)$",
          replacePattern: '$1',
        },
        label: 'Email ID',
        placeholder: 'youremail@example.com',
        errorMessages: {
          pattern: 'Please enter a valid email id',
          required: 'Enter your email id',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
    ],
  },
  otpVerification: {
    formName: 'OTAC Number',
    navigateTo: '/auth/forgotpassword',
    headline: 'Enter Authorization Code',
    introText: 'We have sent SMS to:',
    ctaName: 'Next',
    fields: [
      {
        attribute: 'otac',
        dropdown: false,
        fieldType: {
          code: 'TEXT',
          name: 'TEXT',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.otpVerification,
          name: FORM_TYPES.otpVerification,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 6,
          minLength: 6,
          pattern: '.*',
          jsPattern: '^\\d{6}$',
          replacePattern: '$1',
        },
        placeholder: '6 digit code',
        errorMessages: {
          pattern: 'Please enter digits or numbers',
          required: 'Please enter OTAC',
          minLength: 'Please enter 6 digit OTAC',
          maxLength: 'Please enter 6 digit OTAC',
        },
        disabled: false,
      },
    ],
  },
  resetPassword: {
    formName: 'Reset Password',
    headline: 'Reset Password',
    seo: { metaTitle: 'Reset Password' },
    navigateTo: '/auth/login',
    introText: 'Please fill in the field below to reset your current password.',
    ctaName: 'Reset password',
    successMessage: 'Your password has been reset successfully!  [Login](/auth/login)',
    media: {
      imageUrl: '/images/digilinklabs-natasa-grabovac.jpg',
    },
    fields: [
      {
        attribute: 'password',
        dropdown: false,
        fieldType: {
          code: 'PASSWORD',
          name: 'PASSWORD',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.resetPassword,
          name: FORM_TYPES.resetPassword,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          replacePattern: '$1',
        },
        label: 'Password',
        placeholder: 'New password',
        errorMessages: {
          pattern: 'Not matching regex',
          required: 'Please enter a password',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
      {
        attribute: 'cPassword',
        dropdown: false,
        fieldType: {
          code: 'PASSWORD',
          name: 'PASSWORD',
        },
        prepopulate: false,
        required: true,
        visible: true,
        type: {
          code: FORM_TYPES.registration,
          name: FORM_TYPES.registration,
        },
        uiType: 'null',
        unmaskedChars: '0',
        validation: {
          maxLength: 150,
          pattern: '.*',
          replacePattern: '$1',
        },
        label: 'Confirm Password',
        placeholder: 'Confirm your new password',
        errorMessages: {
          pattern: 'Not matching regex',
          required: 'Enter your confirm password',
          minLength: 'Missing Input',
          maxLength: 'Too Long',
        },
        disabled: false,
      },
    ],
  },
};
