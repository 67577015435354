import { GlobalStyle, theme, GlobalContext } from '@digilinklabs/components';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Head from 'next/head';
import { IKContext } from 'imagekitio-react';
import AppGlobalStyles from '@styles/appGlobalStyles';
import { DEFAULT_SEO } from '@constants';
import App from 'next/app';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

export function reportWebVitals(metric) {
  // eslint-disable-next-line no-undef,no-console
  console.log(metric);
}

function MyApp({ Component, pageProps, ...restProps }) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="shortcut icon"
          href="https://ik.imagekit.io/uqv5qo2qiyv/tr:f-png,pr-true,w-16,h-16/images/Digilinklabs.png"
        />
      </Head>
      <IKContext urlEndpoint="https://ik.imagekit.io/uqv5qo2qiyv">
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <GlobalStyle />
            <AppGlobalStyles />
            <GlobalContext.Provider value={{ ...DEFAULT_SEO }}>
              <Component {...pageProps} {...restProps} />
            </GlobalContext.Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </IKContext>
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps }
}
export default MyApp;
