import { createGlobalStyle } from 'styled-components';

const AppGlobalStyles = createGlobalStyle`
    .forgot-password {
      a {
        color: ${({ theme }) => theme.palette.green.green8};
        ${({ theme }) => theme.typography.subhead2}
      }
    }
    .text-align-center {
            text-align: center;
    }
`;

export default AppGlobalStyles;
